.Breadcrumb {
  height: 57px;
  display: flex;
  align-items: center;
  margin-left: 20px;

  .returnButton {
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5fae\8f6f\96c5\9ed1", Arial, sans-serif, "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5fae\8f6f\96c5\9ed1", Arial, sans-serif;
    font-weight: 400;
    color: #b7bdc4;
    line-height: 57px;
    margin-right: 20px;
    margin-top: 2px;
    cursor: pointer;
  }

  .returnButton:hover {
    color: #ff7d3b;

    .returnIcon {
      fill: #ff7d3b;
    }
  }

  .returnIcon {
    font-size: 12px;
    fill: #b7bdc4;
  }

  .returnIcon:hover {
    fill: #ff7d3b;
  }
}

.content {
  .title {
    display: flex;
    justify-content: space-between;
    padding-right: 30px;

    .titleLeft {
      display: flex;
      align-items: center;
      height: 20px;

      span {
        display: inline-block;
        height: 10px;
        width: 10px;
        border-radius: 5px;
        margin-right: 8px;
        background-color: #ff7d3b;
      }

      div {
        font-size: 18px;
        font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5fae\8f6f\96c5\9ed1", Arial, sans-serif, "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5fae\8f6f\96c5\9ed1", Arial, sans-serif;
        font-weight: 500;
        color: #002855;
      }
    }

    .titleRight {
      width: 200px;
      display: flex;
      justify-content: space-between;
    }
  }

  .inner {
    padding: 40px 0 40px 150px;

    .innerCol {
      height: 32px;
      line-height: 32px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      font-size: 14px;
      font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5fae\8f6f\96c5\9ed1", Arial, sans-serif, "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5fae\8f6f\96c5\9ed1", Arial, sans-serif;
      font-weight: 400;
      color: #364a63;

      .value {
        margin-left: 10px;
        padding: 0 15px;
        min-width: 192px;
        max-width: 380px;
        height: 40px;
        display: flex;
        align-items: center;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #dbdfea;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.editContent {
  height: 100%;
  position: relative;

  .editTitle {
    display: flex;
    align-items: center;
    height: 20px;

    span {
      display: inline-block;
      height: 10px;
      width: 10px;
      border-radius: 5px;
      margin-right: 8px;
      background-color: #ff7d3b;
    }

    div {
      font-size: 18px;
      font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5fae\8f6f\96c5\9ed1", Arial, sans-serif, "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5fae\8f6f\96c5\9ed1", Arial, sans-serif;
      font-weight: 500;
      color: #002855;
    }
  }

  .editBody {
    width: 520px;
    margin-left: 153px;
    margin-top: 40px;

    :global {
      .ant-form-item-control-input-content {
        border: 1px solid #dbdfea;
        border-radius: 4px;
      }
    }
  }

  .userName {
    display: flex;
    align-items: center;
    height: 32px;
    margin-bottom: 23px;
    line-height: 32px;

    .editValue {
      width: 192px;
      height: 32px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      padding-left: 10px;
    }
  }

  .footerButton {
    position: absolute;
    width: 100%;
    margin: 0 auto;
    bottom: 41px;

    div {
      width: 250px;
      margin: 0 auto;

      :global {
        .ant-btn-primary {
          background: linear-gradient(270deg, #FF9849 0%, #FE7034 100%) !important;
          border: 0;
          padding: 0 30px;
          margin-right: 20px;
        }

        .ant-btn-default {
          padding: 0 30px;
        }
      }
    }
  }
}

.modalTatle {
  span {
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5fae\8f6f\96c5\9ed1", Arial, sans-serif, "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5fae\8f6f\96c5\9ed1", Arial, sans-serif;
    font-weight: 400;
    color: #b9b9b9 !important;
    margin-left: 10px;
  }
}

