.loginOutWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .loginOut {
    width: 16px;
    height: 16px;
    background: url("../../assets/images/login_out.png");
    background-size: 100% 100%;
  }
}



.user {
  color: #414141;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;

  :global {
    .ant-avatar {
      width: 40px !important;
      height: 40px !important;
      line-height: 40px !important;
      font-size: 20px !important;
    }
  }

}

.name_line {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .name_tag {
    width: 65px;
    height: 25px;
    background: rgba(234,246,255,1);
    border-radius: 13px;
    font-weight: 400;
    line-height: 24px;
    color: #39689D;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.account_line {
  color: #D2D2D2;
  margin: 7px 0;

  &:hover {
    transform: scale(1.02);
    transition: transform .1s;
  }
}

.cert_line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;

  .no_cert {
    margin-right: 8px;
    color: #FF9408;
  }

  .to_cert {
    color: #FE7034;

    &:hover {
      transform: scale(1.02);
      transition: transform .1s;
    }
  }
}