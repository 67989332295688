
.header_line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding: 12px 0;

  .header_back {
    color: #7f8dff;
    cursor: pointer;
  }


  .header_name {
    font-size: 16px;
    font-weight: bold;
  }
}

.step_warp {
  width: 100%;
  margin: 24px 0;
  padding: 0 5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .item {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .step_num {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 8px;
    }

    .completed_item {
      color: #fff;
      background-color: #00CF92;
    }

    .current_item {
      color: #fff;
      background-color: var(--custom-primary-color);
    }

    .last_item {
      color: #fff;
      background-color: var(--custom-content-border-color)
    }

    .step_name {
      font-size: 16px;
      font-weight: bold;
    }

    .step_split_line {
      flex-grow: 1;
      height: 1px;
      background-color: var(--custom-content-border-color);
      margin: 0 12px;
    }
  }
}

.scroll_main {
  max-height: 500px;
  overflow-y: scroll;
}

.form_warp {

  :global(.ant-radio-inner::after) {
    background-color: var(--custom-primary-color);
  }


  .desc_warp {
    padding: 0 120px;
    margin-bottom: 20px;
  }

  .form_content_warp {
    padding: 12px 24px;
  }

}

.footer {
  padding: 20px;

  .checked_line {
    margin: 12px 0;

    .checked_line_read {
      padding: 0 4px 0 8px;
    }

    .checked_line_name {
      color: var(--custom-link-color);
      cursor: pointer;
    }
  }
}



