
.uploadWarp {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .uploaddesc {
    padding-left: 8px;
    transform: translateY(10px);
  }

  & .ant-upload-list .ant-upload-list-text {
    display: none !important;
  }
}


.tableWarp {
  //padding-left: 120px;
}