.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 20px;

  svg {
    width: 72px;
    height: 72px
  }

  .notice_text {
    margin: 20px 0;
    text-align: center;
  }
}