.content{
  .ant-table-wrapper {
    width: 100%;
  }
  :global {
    table {
      border: 1px solid #DBDFEA;
    }
    .ant-table-tbody {
      td {
        color: var(--custom-table-td-color) !important;
        background: var(--custom-table-body-bg) !important;
        border: 1px solid #DBDFEA !important;
        padding: 0 10px !important;

        .ant-typography {
          color: var(--custom-table-td-color) !important;
        }
      }

      /*td[class*="ant-table-cell-fix"] {
        background: var(--custom-table-fix-body-bg) !important;
        background: var(--custom-table-fix-body-bg1) !important;
      }

      tr:nth-child(2n) {
        background: #f5f4f4;

        td[class*="ant-table-cell-fix"] {
          background: #f5f4f4 !important;
        }
      }*/
    }
    .ant-table-measure-row {
      display: none;
    }
    .ant-table-tbody .ant-table-row td{
      padding: 0 10px !important;
    }
    .ant-table-thead th{
      padding: 0 10px !important;
      border: 1px solid #DBDFEA !important;
    }
  }
}