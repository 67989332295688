@font-face {
    font-family: ""Helvetica Neue", Helvetica, "PingFang SC","Hiragino Sans GB", "Microsoft YaHei", "\5fae\8f6f\96c5\9ed1", Arial,sans-serif ";
    src: url('../assets/fonts/SourceHanSansCN-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "HanSan";
    src: url('../assets/fonts/SourceHanSansCN-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "FZZhengHeiS-DB-GB";
    src: url('../assets/fonts/FZZhengHeiS-DB-GB.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "RTWS BanHei G0v1";
    src: url('../assets/fonts/RTWS-BanHei-G0v1.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "YSBTH";
    src: url('../assets/fonts/YSBTH.ttf');
    font-weight: normal;
    font-style: normal;
}