.checkedAddressWarp {
  width: 100%;
  height: 88px;
  padding: 12px 20px;
  background-color: rgba(255,241,239);
  border: 1px solid #FECEC7;
  margin-bottom: 20px;

  .placeholderText {
    font-size: 12px;
  }

}