// 主色
@primary-color: #ff7d3b; // 全局主色 table栏选中色/选中色/标签色/主按钮色
// 辅色
@link-color: #ff7336; // 链接色

// a,
a:hover {
  color: var(--custom-link-color) !important;
}

.ant-btn-link:not([disabled]) {
  color: @link-color !important;
  // background-color: @link-color !important;
}

// .ant-btn-primary {
//   background-color: @primary-color !important;
// }
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: @primary-color !important;
  border-color: @primary-color !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: @primary-color !important;
}

.ant-checkbox-checked::after {
  border-color: @primary-color;
}

.ant-table {
  background: var(--custom-table-bg) !important;
  background-size: 100% 100% !important;
  border: none !important;

  // table {
  //   border: none !important;
  // }
}

.ant-table,
.ant-table-container {
  border: none !important;
}

.ant-table-thead {
  tr {
    background: var(--custom-table-head-bg) no-repeat !important;
    background-size: 100% 100% !important;
  }

  th {
    background: var(--custom-table-head-bg) !important;
    color: var(--custom-table-head-color) !important;
    border: none !important;
    padding: 0 40px !important;

    &::before {
      display: none;
    }
  }
}

.ant-table-tbody {
  td {
    color: var(--custom-table-td-color) !important;
    background: var(--custom-table-body-bg) !important;
    border: none !important;
    padding: 0 40px !important;

    .ant-typography {
      color: var(--custom-table-td-color) !important;
    }
  }

  td[class*="ant-table-cell-fix"] {
    background: var(--custom-table-fix-body-bg) !important;
    background: var(--custom-table-fix-body-bg1) !important;
  }

  tr:nth-child(2n) {
    background: var(--custom-table-even-tr-bg);

    td[class*="ant-table-cell-fix"] {
      background: var(--custom-table-fix-body-bg) !important;
    }
  }
}

.ant-table-cell-fix-right-first::after {
  border-right: 1px solid var(--custom-table-border-color) !important;
}

.ant-checkbox-inner {
  background: #fff !important;
  border: 1px solid #999 !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: @primary-color !important;
}

.ant-pagination {
  font-size: 16px !important;
}

.ant-pagination-item {
  background: var(--custom-pagination-bg) !important;
  border: none !important;
  margin-right: 24px !important;

  a {
    padding: 0 15px !important;
    color: var(--custom-pagination-a-color) !important;
  }
}

.ant-pagination-item-active {
  position: relative;

  a {
    color: var(--custom-pagination-a-color) !important;
  }

  &::after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: var(--custom-pagination-active-color);
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

button.ant-pagination-item-link,
.ant-select-selector {
  background: var(--custom-pagination-bg) !important;
  color: var(--custom-pagination-color) !important;
}

button.ant-pagination-item-link {
  border: none !important;
}

.ant-pagination-item-ellipsis,
.ant-pagination-options-quick-jumper,
.ant-pagination-total-text {
  color: var(--custom-pagination-color) !important;
}

.ant-pagination .ant-pagination-options-quick-jumper input {
  background-color: var(--custom-form-item-bg) !important;
  color: var(--custom-form-item-input-color);
  border: 1px solid var(--custom-form-item-border-color) !important;
}

// form
.ant-form-item-label {
  label {
    color: var(--custom-form-item-label-color) !important;
  }
}

.ant-form-item {
  // color: var(--custom-form-item-color);

  // .ant-input,
  // .ant-picker,
  // .ant-input-number,
  // .ant-select-selector {
  //   background-color: var(--custom-form-item-bg) !important;
  //   border: 1px solid var(--custom-form-item-border-color) !important;
  //   color: var(--custom-form-item-input-color) !important;
  //   border-radius: 4px !important;
  //   // height: 40px !important;

  //   input {
  //     background-color: var(--custom-form-item-bg) !important;
  //     color: var(--custom-form-item-input-color) !important;
  //   }

  //   span {
  //     color: var(--custom-form-item-input-color) !important;
  //     border-color: var(--custom-form-item-input-color) !important;
  //   }
  // }

  // .ant-input-number-handler-wrap {
  //   background: var(--custom-form-item-bg) !important;

  //   .ant-input-number-handler {
  //     border-left: 1px solid #d9d9d9;
  //   }

  //   .ant-input-number-handler-down {
  //     border-top: 1px solid #d9d9d9;
  //   }
  // }
}

.ant-select-selector {
  background: var(--custom-form-item-bg) !important;
  // border: 1px solid var(--custom-pagination-border-color) !important;
  color: var(--custom-form-item-color) !important;
}

.ant-select-arrow {
  color: #323232 !important;
}

.ant-upload-select {
  background-color: var(--custom-upload-bg-color) !important;
}

.ant-upload {
  &>div {
    color: var(--custom-upload-color) !important;
  }
}

.ant-checkbox-wrapper,
.ant-radio-wrapper {
  color: var(--custom-form-item-label-color) !important;
}

.ant-checkbox-inner,
.ant-radio-inner {
  background: #fff !important;
  border: 1px solid #999 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: var(--custom-primary-color) !important;
  border: 1px solid var(--custom-primary-color) !important;
}

.ant-switch-checked {
  background-color: var(--custom-switch-checked-bg) !important;
}

.ant-switch {
  border: var(--custom-switch-border) !important;
}

.ant-switch-small {
  height: var(--custom-switch-height) !important;
  line-height: var(--custom-switch-height) !important;
}

// menu
.ant-menu-submenu-popup>.ant-menu {
  background: var(--custom-menu-top-pop-bg) !important;
  background-size: 100% 100% !important;
  color: var(--custom-menu-left-item-color) !important;

  .ant-menu-item-selected,
  .ant-menu-submenu-selected {
    background: var(--custom-menu-left-item-selected-bg) !important;
    color: var(--custom-menu-left-item-selected-color) !important;
  }
}

.ant-menu-submenu-title {
  color: var(--custom-menu-left-item-color);

  &:hover {
    color: var(--custom-menu-left-item-hover-color) !important;
    background: var(--custom-menu-left-item-hover-bg);

    :global(.ant-menu-submenu-arrow)::before {
      background: var(--custom-menu-left-item-hover-color) !important;
    }

    :global(.ant-menu-submenu-arrow)::after {
      background: var(--custom-menu-left-item-hover-color) !important;
    }
  }

  i {
    color: var(--custom-menu-left-item-color);
  }
}

.ant-menu-item {

  &:hover,
  &:active {
    color: var(--custom-menu-left-item-hover-color) !important;
    background: var(--custom-menu-left-item-hover-bg) !important;

    :global(.ant-menu-submenu-arrow)::before {
      background: var(--custom-menu-left-item-hover-color) !important;
    }

    :global(.ant-menu-submenu-arrow)::after {
      background: var(--custom-menu-left-item-hover-color) !important;
    }
  }
}

// modal
.ant-modal-content {
  background: var(--custom-modal-body-bg);
  background-size: 100% 100%;
  border-radius: 10px !important;
  overflow: hidden;

  .ant-modal-close {
    color: var(--custom-form-item-label-color) !important;
  }
}

.ant-modal-header {
  background: var(--custom-modal-title-bg) !important;
  background-size: 100% 100% !important;
  border-bottom: 1px solid var(--custom-modal-border-color) !important;
  padding: 24px 24px 24px 38px !important;

  .ant-modal-title {
    color: var(--custom-modal-title-color) !important;
    position: relative;

    &::before {
      content: "";
      width: 3px;
      height: 90%;
      background: #fe7134;
      position: absolute;
      left: -11px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.ant-modal-body {
  .w-e-bar-item button:hover {
    background-color: var(--custom--w-e-toolbar-active-bg-color) !important;
    color: var(--custom--w-e-toolbar-active-color) !important;
  }

  .w-e-bar {
    background-color: var(--custom--w-e-toolbar-bg-color) !important;
    color: var(--custom--w-e-toolbar-color) !important;
  }

  .w-e-bar-item button {
    color: var(--custom--w-e-toolbar-color) !important;

    &>svg {
      fill: var(--custom--w-e-toolbar-color) !important;
    }
  }

  .w-e-text-container {
    background-color: var(--custom--w-e-textarea-bg-color) !important;
    color: var(--custom--w-e-textarea-color) !important;
  }

  .container-wrapper {
    border-color: var(--custom-container-wrapper-border-color) !important;
  }

  .container-title {
    border-bottom: 1px solid var(--custom-container-title-border-color) !important;
    color: var(--custom-container-title-color) !important;
    background: var(--custom-container-title-bg) !important;
    background-size: 100% 100% !important;
  }

  .container-content {
    background: var(--custom-container-content-bg) !important;
    background-size: 100% !important;
  }

  data-w-e-toolbar {
    border-bottom: 1px solid var(--custom-container-wrapper-border-color) !important;
  }

  .ant-form-item-control-input-content {
    &>div {
      border-color: var(--custom-container-wrapper-border-color) !important;
    }
  }

  .array-tips {
    color: var(--custom-array-color) !important;
    background-color: var(--custom-array-bg-color) !important;
  }

  .array-btn-add {
    color: var(--custom-array-btn-color) !important;
    background-color: var(--custom-array-btn-bg-color) !important;
    border: 1px solid var(--custom-array-btn-border-color) !important;
  }

  .array-btn-inlineAdd,
  .array-btn-copy,
  .array-btn-del {
    color: var(--custom-array-btn-color) !important;
  }

  .import-wrapper {
    .import-box {
      border-color: var(--custom-import-box-border-color) !important;
    }

    .import-title,
    .import-warning {
      background: var(--custom-import-bg) !important;
    }

    .import-info-title,
    .import-info {
      background: var(--custom-import-bg) !important;
      color: var(--custom-import-color) !important;
    }

    .import-action {
      background: var(--custom-import-bg) !important;
    }

    .import-footer {
      border-top: 1px solid var(--custom-import-footer-border-color) !important;
    }

    .ant-steps-item-title {
      color: var(--custom-import-step-color) !important;
    }

    .import-success {
      .import-success-content {
        color: var(--custom-import-step3-content-color) !important;
      }
    }
  }
}

.ant-modal-footer {
  border-top: 1px solid transparent !important;
}

.ant-modal-confirm-title,
.ant-modal-confirm-content {
  color: var(--custom-modal-confirm-title-color) !important;
}

.ant-empty-description {
  color: var(--custom-modal-confirm-title-color) !important;
}

// // tabs
// .ant-tabs {
//   .ant-tabs-nav::before {
//     border-color: var(--custom-tabs-tab-border-color) !important;
//   }
//   .ant-tabs-tab-btn {
//     color: var(--custom-tabs-tab-color) !important;
//   }
//   .ant-tabs-tab-active {
//     position: relative;
//     background: var(--custom-tabs-nav-bg) !important;
//     background-size: 100% 100% !important;
//     &::after {
//       display: inline-block;
//       height: 4px;
//       width: 100%;
//       background-color: var(--custom-tabs-tab-active-color);
//       content: "";
//       position: absolute;
//       top: 0;
//       left: 0;
//       box-shadow: 0 2px 8px var(--custom-tabs-tab-active-color);
//       border-top-left-radius: 2px;
//       border-top-right-radius: 2px;
//     }
//   }

//   .ant-tabs-tab {
//     margin-left: 0 !important;
//     background: var(--custom-tabs-tab-bg) !important;
//     border-color: var(--custom-tabs-tab-border-color) !important;
//     background-size: 100% 100% !important;
//   }
// }

// button
.ant-btn:not(.ant-btn-round) {
  border-radius: 4px !important;
}

.ant-btn-default {
  background: var(--custom-modal-footer-btn-default-bg) !important;
  color: var(--custom-modal-footer-btn-default-color) !important;
  background-size: 100% 100% !important;
  border-color: var(--custom-modal-footer-btn-default-border-color) !important;
}

.ant-btn-primary {
  background: linear-gradient(270deg, #ff9849 0%, #fe7034 100%) !important;
  color: var(--custom-modal-footer-btn-primary-color) !important;
  background-size: 100% 100% !important;
  border-color: rgba(0, 0, 0, 0) !important;
}

// container
.container-wrapper {
  border-color: var(--custom-container-wrapper-border-color) !important;
}

.container-title {
  border-bottom: 1px solid var(--custom-container-title-border-color) !important;
  color: var(--custom-container-title-color) !important;
  background: var(--custom-container-title-bg) !important;
  background-size: 100% 100% !important;
}

.container-content {
  background: var(--custom-container-content-bg) !important;
  background-size: 100% !important;
}

.PageModal {
  background: var(--custom-page-bg-color) !important;

  .ModalHeader {
    background: var(--custom-title-bg) !important;
    border-bottom: 1px solid var(--custom-title-border-color) !important;

    .HeaderTitle {
      color: var(--custom-title-color) !important;
    }
  }

  .ModalContent {
    background: var(--custom-content-bg) !important;
    background-size: 100% 100% !important;

    &>div {
      background: var(--custom-content-bg-color) !important;
      background-size: 100% 100% !important;
    }
  }

  .HeaderColseBtn {
    color: var(--custom-title-color);
  }
}

::-webkit-scrollbar {
  z-index: 11;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background: rgba(255, 255, 255, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  width: 6px;
  background: #b4bccc;
}

::-webkit-scrollbar-track-piece {
  background: rgba(255, 255, 255, 0.1);
  width: 6px;
}

.updatePasswordModal {

  // :global {
  .ant-modal-content {
    overflow: unset !important;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }

  // }
}

.ant-btn-round,
.ant-btn-lg {
  border-radius: 4px !important;
}

.ant-modal-title {
  font-weight: bold !important;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sureBtn {
  width: 110px !important;
  height: 46px !important;
  background: linear-gradient(95deg, #fe7034 0%, #ff9849 100%) !important;
  font-size: 16px !important;
  font-family: Helvetica Neue !important;
  font-weight: 400;
  color: #ffffff !important;

  &:hover {
    background: linear-gradient(95deg, #fe7034 0%, #ff9849 100%);
    opacity: 0.7;
  }

  &:active {
    background: linear-gradient(95deg, #ff6828 0%, #ff8223 100%);
    opacity: 1;
  }
}

.cancelBtn {
  width: 110px !important;
  height: 46px !important;
  margin-left: 20px;
  background: rgba(243, 245, 248, 0.39);
  font-size: 14px;
  font-family: Helvetica Neue !important;
  font-weight: 400;
  line-height: 24px;
  color: #b1b1b1;
}

.ant-dropdown-menu-item {
  padding: 5px 20px !important;
}

.ant-tabs-ink-bar {
  background: linear-gradient(270deg, #ee9843 0%, #e06c57 100%) !important;
}

.ant-tabs-tab {
  //padding: 5px 0 !important;
}

.ant-tabs-tab-btn {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  color: #333333;
  text-align: center;

  &:hover {
    color: #ff7032;
  }

  .ant-anchor-link {
    padding: 0 !important;
  }

  a {
    color: #333333;
    padding: 0 !important;
  }
}

// .ant-tabs-tab-active .ant-tabs-tab-btn {
//   font-size: 16px;
//   font-weight: bold;
//   line-height: 22px;
//   color: #2d2d2d !important;
// }