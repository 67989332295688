.tabWrapper {
  width: 850px;
  margin: -8px -16px;

  :global {
    .ant-tabs-nav {
      padding: 11px 0 11px 23px;
      background-color: #fff9f4;
    }

    .ant-tabs-tab {
      .ant-tabs-tab-btn {
        font-size: 16px;
        font-weight: 500;
      }

      &:hover,
      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #fe7134;
      }
    }

    //.ant-tabs-ink-bar {
    //  background: #fe7134;
    //}

    .ant-tabs-content {
      padding: 22px 4px;
      background-color: #fff;
    }
  }
}

.tabContent {
  // width: 700px;
  height: 240px;
  padding-right: 24px;

  .title {
    font-size: 16px;
    font-weight: bold;
    color: #002855;
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
      gap: 10px;
      img {
        width: 26px;
        height: 26px;
      }
    }
  }

  .btn {
    font-size: 12px;
    box-shadow: none;
    border-color: #e2e2e2 !important;
    color: #b7bdc4 !important;
    background-color: #fff !important;
  }

  .listWrapper {
    display: grid;
    // grid-row-gap: 30px;
    // grid-column-gap: 26px;
    gap: 14px;
    // grid-template-columns: repeat(3, 192px);
    grid-template-columns: repeat(3, 192px); /* 3 列，等宽 */
    grid-template-rows: repeat(3, 1fr); /* 3 行，等高 */
    // justify-content: space-between;
    // height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .content {
    cursor: pointer;
    padding: 14px;
    transition: all 0.2s;
    border-radius: 3px;
    &:hover {
      background-color: #ececec;
      color: #000;
    }
  }

  .name {
    font-size: 16px;
    font-weight: 400;
    color: #002855;
    display: flex;
    gap: 5px;
    align-items: center;
    .span {
      // display: inline-block;
      width: 173px;
    }
    img {
      width: 16px;
      height: 14px;
    }
  }

  .intro {
    font-size: 12px;
    font-weight: 400;
    color: #b7bdc4;
  }

  .name .span,
  .intro {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
