body[apptheme='ks'] {
  // 页面背景图片
  --custom-primary-color: #77A5D1;
  --custom-page-bg-color: #19222f;
  --custom-layout: transparent;

  // header
  --custom-page-header-bg: url('/styles/theme/ks/page_header_bg.png');

  // menu
  --custom-menu-top-active-bg: url('/styles/theme/ks/menu_active_bg.png');
  --custom-menu-top-color: #8FC5E7;
  --custom-menu-top-active-color: #FFFFFF;
  --custom-menu-top-pop-bg: url('/styles/theme/ks/menu_pop_bg.png');
  --custom-menu-border-color: transparent;

  --custom-sider-left-bg: url('/styles/theme/ks/sider_left_bg.png');
  --custom-sider-trigger-color: #77A5D1;
  --custom-sider-trigger-bg: transparent;

  --custom-menu-left-sub-bg: rgba(48, 155, 253, .7);
  --custom-menu-left-sub-color: #fff;
  --custom-menu-left-item-color: #8FC5E7;
  --custom-menu-left-item-bg: transparent;
  --custom-menu-left-item-selected-color: #fff;
  --custom-menu-left-item-selected-bg: rgba(48, 155, 253, .7);
  --custom-menu-left-item-selected-parent-color: #8FC5E7;
  --custom-menu-left-item-hover-bg: rgba(48, 155, 253, .2);
  --custom-menu-left-item-hover-color: #8FC5E7;

  // title
  --custom-title-bg: rgba(69, 96, 122, 0.1);;
  --custom-title-color: #6289AF;
  --custom-title-border-color: transparent;
  // content
  --custom-content-bg-color: rgba(69, 96, 122, .1);
  --custom-content-bg: url('/styles/theme/ks/content_bg.png');
  --custom-content-border-color: tranparent;
  // 表格背景图片
  --custom-table-bg: url('/styles/theme/ks/table_bg.png');
  --custom-table-head-bg: url('/styles/theme/ks/table_head.png');
  --custom-table-head-color: #77A5D1;
  --custom-table-th-bg: #22394f;
  --custom-table-body-bg: transparent;
  --custom-table-fix-body-bg: #263545;
  --custom-table-fix-body-bg1: #212e3c;
  --custom-table-border-color: transparent;
  --custom-table-even-tr-bg: rgba(98, 137, 175, 0.08);
  --custom-table-td-color: #fff;
  // form
  --custom-form-item-color: #fff;
  --custom-form-item-bg: transparent !important;
  --custom-form-item-border-color: #6289AF !important;
  --custom-form-item-label-color: #77A5D1 !important;
  --custom-form-item-input-color: #6289AF !important;
  --custom-input-number-handler-border-color: transparent !important;
  --custom-upload-color: #fff !important;
  --custom-upload-bg-color: transparent !important;
  --custom-switch-checked-bg: #77A5D1 !important;
  --custom-switch-border: 1px solid #77A5D1;
  --custom-switch-height: 18px;
  --custom-ant-inner-color: #77A5D1;
  // pagination
  --custom-pagination-bg: transparent !important;
  --custom-pagination-color: #77A5D1 !important;
  --custom-pagination-border-color: #77A5D1 !important;
  --custom-pagination-a-color: #77A5D1 !important;

  // modal
  --custom-modal-content-bg: transparent;
  --custom-modal-border-color: transparent;
  --custom-modal-title-bg: url('/styles/theme/ks/modal_title_bg.png');
  --custom-modal-title-color: #fff;
  --custom-modal-body-bg: url('/styles/theme/ks/modal_content_bg.png');
  --custom-modal-footer-btn-primary-color: #fff;
  --custom-modal-footer-btn-primary-bg: url('/styles/theme/ks/primary_btn_bg.png');
  --custom-modal-footer-btn-primary-bg-color: transparent;
  --custom-modal-footer-btn-primary-border-color: transparent;
  --custom-modal-footer-btn-default-color: #fff;
  --custom-modal-footer-btn-default-bg: url('/styles/theme/ks/default_btn_bg.png');
  --custom-modal-footer-btn-default-border-color: transparent;
  --custom-modal-footer-margin-top: -63px;
  --custom-modal-confirm-title-color: #77A5D1;
  // tabs
  --custom-tabs-nav-bg: url('/styles/theme/ks/default_btn_bg.png');
  --custom-tabs-tab-bg: transparent;
  --custom-tabs-tab-color: #fff;
  --custom-tabs-tab-active-color: rgba(48, 155, 253, .2);
  --custom-tabs-tab-border-color: rgba(48, 155, 253, .2);

  // container
  --custom-container-wrapper-border-color: #77A5D1;
  --custom-container-title-border-color: tranparent;
  --custom-container-title-color: #77A5D1;
  --custom-container-title-bg: url('/styles/theme/ks/page_header_bg.png');
  --custom-container-content-bg: url('/styles/theme/ks/content_bg.png');
  --custom-container-before-color: #77A5D1;

  // import
  --custom-import-box-border-color: rgba(48, 155, 253, .2);
  --custom-import-color: #fff;
  --custom-import-bg: transparent;
  --custom-import-border-color: rgba(48, 155, 253, .2);
  --custom-import-footer-border-color: transparent;
  --custom-import-step-color: #fff;
  --custom-import-step3-content-color: #fff;

  // array
  --custom-array-bg-color: transparent;
  --custom-array-color: #77A5D1;
  --custom-array-btn-color: #77A5D1;
  --custom-array-btn-bg-color: transparent;
  --custom-array-btn-border-color: #77A5D1;

  // rich
  --custom--w-e-toolbar-bg-color: #17212f;
  --custom--w-e-toolbar-color: #fff;
  --custom--w-e-textarea-bg-color: #17212f;
  --custom--w-e-textarea-color: #77A5D1;
  --custom--w-e-toolbar-active-bg-color: #77A5D1;
  --custom--w-e-toolbar-active-color: #fff;
}
