.title_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;

  .title_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .title_color_block {
      width: 4px;
      height: 22px;
      background-color: var(--custom-primary-color);
      margin-right: 8px;
    }

    .title_text {
      font-size: 16px;
      font-weight: bold;
    }

    .title_desc {
      font-size: 14px;
      color: #999;
      margin-left: 8px;
    }
  }

  .title_right {

  }
}