.layout {
    width: 100%;
    height: 100%;
    // min-width: 1800px;
    overflow: auto;
    background: var(--custom-page-bg-color);
    position: relative;
    z-index: 1;

    :global(.ant-layout) {
        background: transparent;
    }
}

.header {
    width: 100%;
    height: 70px;
    line-height: 70px;
    background-color: var(--custom-page-header-bg);
    padding: 0 20px;
    position: sticky;
    top: 0;
    z-index: 999;
}

.headerWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
}

.logo {
    flex-shrink: 0;
    height: 100%;
    margin-right: 5px;
    text-align: center;
    overflow: hidden;
    color: #202020;
    display: flex;
    align-items: center;
    gap: 16px;
    img {
        width: auto !important;
        height: 40px !important;
        margin-top: -6px !important;
    }

    span {
        font-size: 22px;
        font-weight: 400;
        cursor: pointer;
        font-family: FZZhengHeiS-DB-GB, FZZhengHeiS-DB-GB;
    }
}

.top-menu {
    display: flex;
    // flex: 1;
    // width: 0;
    align-items: center;
    justify-content: space-between;
    // overflow: auto;
}
.user {
    flex-shrink: 0;
    :global {
        .user-opt {
            display: flex;
            align-items: center;
            gap: 8px;
            height: 40px;
            padding: 0 16px;
            border-radius: 6px;
            font-size: 16px;
            color: #374567;
            font-weight: 550;
            > div {
                cursor: pointer;
                &:hover {
                    color: #fe7235;
                }
            }
        }
    }
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-top: 70px;
    overflow-x: hidden;
}

.footer {
    padding: 0;
}

.floatBtn {
    position: fixed;
    top: 60%;
    right: 0;
    width: 110px;
    height: 110px;
    background: linear-gradient(180deg, #ffffff 0%, #fafafa 100%);
    box-shadow: 0px 15px 12px 1px rgba(3, 21, 61, 0.06);
    border-radius: 10px;
    font-weight: 500;
    color: #002855;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    img {
        width: 72px;
        height: 72px;
    }
}
