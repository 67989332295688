.content {

  .title {
    color: #002855;
    font-size: 24px;
    font-weight: 500;
  }

  :global(.ant-modal-body) {
    border-top: 1px solid #CCD7E9;
  }

  .docx_preview_content {
    overflow: auto;
    max-height: 700px;
    padding: 20px
  }

  .footer {
    width: 100%;
    height: 50px;
    border-top: 1px solid #CCD7E9;
    display: flex;
    justify-content: center;

    :global {
      .ant-btn-primary {
        background: linear-gradient(270deg, #FF9849 0%, #FE7034 100%) !important;
        border: 0;
        margin-top: 6px;
        width: 420px;
      }
    }
  }
}

