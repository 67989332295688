body[apptheme='xs'] {
  --custom-primary-color: #FE7235;
  // 链接
  --custom-link-color: #FF8F44;
  // 页面背景图片
  --custom-page-bg-color: #F5F7FB;
  --custom-layout: transparent;

  // header
  --custom-page-header-bg: rgba(255, 255, 255, 0.3);

  // menu
  --custom-menu-top-color: #374567;
  --custom-menu-top-active-color: #FE7235;
  --custom-menu-top-pop-bg: #fff;
  --custom-menu-border-color: transparent;
  --custom-menu-top-sub-icon: url('/styles/theme/xs/down.png');
  --custom-menu-top-sub-active-icon: url('/styles/theme/xs/down-active.png');

  --custom-sider-left-bg: #fff;
  --custom-sider-trigger-color: #FE7235;
  --custom-sider-trigger-bg: #fff;

  --custom-menu-left-sub-bg: rgba(16, 35, 67, .01);
  --custom-menu-left-sub-color: rgba(16, 35, 67, .01);
  --custom-menu-left-item-color: #8094ae;
  --custom-menu-left-item-bg: transparent;
  --custom-menu-left-item-selected-color: #FE7235;
  --custom-menu-left-item-selected-bg: #f0f4ff;
  --custom-menu-left-item-selected-parent-color: #FE7235;
  --custom-menu-left-item-hover-bg: transparent;
  --custom-menu-left-item-hover-color: #FE7235;

  // title
  --custom-title-bg: #fff;
  --custom-title-color: #002855;
  --custom-title-border-color: #dbdfea;
  // content
  --custom-content-bg-color: #fff;
  --custom-content-bg: none;
  --custom-content-border-color: #dbdfea;
  // 表格背景图片
  --custom-table-bg: transparent;
  --custom-table-head-bg: #EBF3FD;
  --custom-table-head-color: #333;
  --custom-table-th-bg: #EBF3FD;
  --custom-table-body-bg: transparent;
  --custom-table-fix-body-bg: #fff;
  --custom-table-fix-body-bg1: #fff;
  --custom-table-border-color: rgba(0, 0, 0, 0.06);
  --custom-table-even-tr-bg: #fbfbfb;
  --custom-table-td-color: #1A2634;
  // form
  --custom-form-item-color: #2A2A2A;
  --custom-form-item-bg: #FBFBFB;
  --custom-form-item-border-color: transparent;
  --custom-form-item-label-color: #333;
  --custom-form-item-input-color: #2A2A2A;
  --custom-input-number-handler-border-color: rgba(233, 233, 233, .24);
  --custom-upload-color: rgba(0, 0, 0, 0.85) !important;
  --custom-upload-bg-color: #fafafa !important;
  --custom-switch-border: none;
  --custom-switch-height: 16px;
  --custom-ant-inner-color: rgba(233, 233, 233, .24);
  // pagination
  --custom-pagination-bg: transparent;
  --custom-pagination-color: #5e5e5e;
  --custom-pagination-border-color: transparent;
  --custom-pagination-active-color: #FE7235;
  --custom-pagination-a-color: #333;
  // modal
  --custom-modal-content-bg: #fff;
  --custom-modal-border-color: transparent;
  --custom-modal-title-bg: #fff;
  --custom-modal-title-color: #343434;
  --custom-modal-body-bg: #fff;
  --custom-modal-footer-btn-primary-color: #fff;
  --custom-modal-footer-btn-primary-bg: #FE7235;
  --custom-modal-footer-btn-primary-bg-color: #FE7235;
  --custom-modal-footer-btn-primary-border-color: #FE7235;
  --custom-modal-footer-btn-default-color: #b1b1b1;
  --custom-modal-footer-btn-default-bg: #F3F5F8;
  --custom-modal-footer-btn-default-border-color: #F3F5F8;
  --custom-modal-footer-margin-top: -63px;
  --custom-switch-checked-bg: #FE7235;
  --custom-modal-confirm-title-color: rgba(0, 0, 0, 0.85);

  // tabs
  --custom-tabs-nav-bg: none;
  --custom-tabs-tab-bg: #fff;
  --custom-tabs-tab-color: #6576ff;
  --custom-tabs-tab-active-color: #6576ff;
  --custom-tabs-tab-border-color: #f0f0f0;

  // container
  --custom-container-wrapper-border-color: rgb(221, 221, 221);
  --custom-container-title-border-color: rgb(244, 244, 244);
  ;
  --custom-container-title-color: #333;
  --custom-container-title-bg: tranparent;
  --custom-container-content-bg: tranparent;
  --custom-container-before-color: #FE7235;

  // import
  --custom-import-box-border-color: #E1E3E6;
  --custom-import-color: #2A2E41;
  --custom-import-bg: #fafafa;
  --custom-import-border-color: #E1E3E6;
  --custom-import-footer-border-color: transparent;
  --custom-import-step-color: rgba(0, 0, 0, 0.45);
  --custom-import-step3-content-color: #2A2E41;

  // array
  --custom-array-bg-color: #f2f2f2;
  --custom-array-color: #7f7f7f;
  --custom-array-btn-color: rgba(0, 0, 0, 0.85);
  --custom-array-btn-bg-color: #fff;
  --custom-array-btn-border-color: rgba(233, 233, 233, .24);

  // rich
  --custom--w-e-toolbar-bg-color: #fff;
  --custom--w-e-toolbar-color: #595959;
  --custom--w-e-textarea-bg-color: #fff;
  --custom--w-e-textarea-color: #333;
  --custom--w-e-toolbar-active-bg-color: #f1f1f1;
  --custom--w-e-toolbar-active-color: #333;
}