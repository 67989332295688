.wrap {
  width: 100%;
  height: 100%;
  padding: 40px 80px;

  .uploadWarp {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    :global(.ant-upload-picture-card-wrapper) {
      width: 120px !important;
    }

    .uploaddesc {
      padding-left: 8px;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
}