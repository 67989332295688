.page-root {
  background-color: #f5f6fa;
  height: 100%;
  flex: 1;

  .page-header {
    position: relative;
    color: #364a63;
    height: 57px;
    line-height: 57px;
    background: #fff;
    // text-indent: 20px;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px #dbdfea solid;
    text-align: left;
  }

  .page-main {
    height: calc(100% - 116px);
    width: calc(100% - 52px);
    margin: 26px auto;
    padding: 20px;
    overflow: auto;
    background: #fff;
    text-align: initial;
  }

}


:global(.confirmwrapper1) {
  .page-header {
    height: 0;
  }
}