@font-face {
  font-family: 'lowcode-icon';
  src: url('../assets/fonts/lowcode-icon.ttf?dcw4a0') format('truetype'),
    url('../assets/fonts/lowcode-icon.woff?dcw4a0') format('woff'),
    url('../assets/fonts/lowcode-icon.svg?dcw4a0#lowcode-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.lc-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'lowcode-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lc-data-histogram-16-regular:before {
  content: '\e947';
}
.lc-data-scatter-20-regular:before {
  content: '\e948';
}
.lc-data-pie-20-regular:before {
  content: '\e949';
}
.lc-data-bar-horizontal-24-regular:before {
  content: '\e94a';
}
.lc-data-area-20-regular:before {
  content: '\e94b';
}
.lc-gantt-chart-24-regular:before {
  content: '\e900';
}
.lc-chart-person-20-regular:before {
  content: '\e901';
}
.lc-chart-multiple-20-regular:before {
  content: '\e902';
}
.lc-arrow-trending-24-regular:before {
  content: '\e903';
}
.lc-arrow-trending-down-16-regular:before {
  content: '\e904';
}
.lc-organization-16-regular:before {
  content: '\e94c';
}
.lc-multiselect-16-filled:before {
  content: '\e94d';
}
.lc-more-vertical-24-filled:before {
  content: '\e94e';
}
.lc-molecule-16-regular:before {
  content: '\e94f';
}
.lc-line-horizontal-3-20-filled:before {
  content: '\e950';
}
.lc-image-24-regular:before {
  content: '\e951';
}
.lc-calendar-clock-16-regular:before {
  content: '\e952';
}
.lc-chat-16-regular:before {
  content: '\e953';
}
.lc-arrow-move-20-filled:before {
  content: '\e946';
}
.lc-tag-16-regular:before {
  content: '\e905';
}
.lc-power-20-regular:before {
  content: '\e906';
}
.lc-open-16-regular:before {
  content: '\e907';
}
.lc-mic-16-regular:before {
  content: '\e908';
}
.lc-mic-off-16-regular:before {
  content: '\e909';
}
.lc-lightbulb-16-regular:before {
  content: '\e90a';
}
.lc-cloud-sync-16-regular:before {
  content: '\e90b';
}
.lc-cloud-error-16-regular:before {
  content: '\e90c';
}
.lc-cloud-16-regular:before {
  content: '\e90d';
}
.lc-alert-off-16-regular:before {
  content: '\e90e';
}
.lc-alert-16-regular:before {
  content: '\e90f';
}
.lc-share-ios-20-filled:before {
  content: '\e910';
}
.lc-share-16-regular:before {
  content: '\e911';
}
.lc-send-16-regular:before {
  content: '\e912';
}
.lc-pause-16-regular:before {
  content: '\e913';
}
.lc-notepad-edit-16-regular:before {
  content: '\e914';
}
.lc-note-16-regular:before {
  content: '\e915';
}
.lc-lock-open-12-regular:before {
  content: '\e916';
}
.lc-lock-closed-12-regular:before {
  content: '\e917';
}
.lc-bluetooth-16-regular:before {
  content: '\e918';
}
.lc-attach-16-regular:before {
  content: '\e919';
}
.lc-document-bullet-list-16-regular:before {
  content: '\e91a';
}
.lc-document-24-regular:before {
  content: '\e91b';
}
.lc-full-screen-minimize-16-filled:before {
  content: '\e954';
}
.lc-full-screen-maximize-16-filled:before {
  content: '\e955';
}
.lc-compass-northwest-20-regular:before {
  content: '\e956';
}
.lc-comment-off-16-regular:before {
  content: '\e957';
}
.lc-collections-20-regular:before {
  content: '\e958';
}
.lc-calendar-agenda-20-regular:before {
  content: '\e959';
}
.lc-bookmark-20-regular:before {
  content: '\e95a';
}
.lc-arrow-previous-12-regular:before {
  content: '\e95b';
}
.lc-arrow-right-24-filled:before {
  content: '\e95c';
}
.lc-arrow-left-24-filled:before {
  content: '\e95d';
}
.lc-apps-list-20-filled:before {
  content: '\e95e';
}
.lc-align-space-evenly-vertical-24-regular:before {
  content: '\e95f';
}
.lc-subtract-circle-16-regular:before {
  content: '\e91c';
}
.lc-info-16-regular:before {
  content: '\e91d';
}
.lc-arrow-minimize-16-regular:before {
  content: '\e91e';
}
.lc-arrow-maximize-16-regular:before {
  content: '\e91f';
}
.lc-arrow-hook-up-right-16-regular:before {
  content: '\e920';
}
.lc-arrow-hook-up-left-16-regular:before {
  content: '\e921';
}
.lc-play-circle-16-regular:before {
  content: '\e922';
}
.lc-play-16-regular:before {
  content: '\e923';
}
.lc-document-copy-24-regular:before {
  content: '\e924';
}
.lc-copy-add-20-regular:before {
  content: '\e925';
}
.lc-copy-16-regular:before {
  content: '\e926';
}
.lc-presence-blocked-16-regular:before {
  content: '\e927';
}
.lc-settings-cog-multiple-24-regular:before {
  content: '\e928';
}
.lc-settings-16-regular:before {
  content: '\e929';
}
.lc-checkmark-circle-12-regular:before {
  content: '\e92a';
}
.lc-checkmark-16-regular:before {
  content: '\e92b';
}
.lc-arrow-counterclockwise-dashes-20-filled:before {
  content: '\e92c';
}
.lc-arrow-clockwise-16-regular:before {
  content: '\e92d';
}
.lc-arrow-down-24-filled:before {
  content: '\e92e';
}
.lc-cloud-arrow-down-16-regular:before {
  content: '\e92f';
}
.lc-arrow-circle-down-16-regular:before {
  content: '\e930';
}
.lc-arrow-up-24-filled:before {
  content: '\e931';
}
.lc-cloud-add-16-regular:before {
  content: '\e932';
}
.lc-arrow-upload-16-regular:before {
  content: '\e933';
}
.lc-chevron-left-12-regular:before {
  content: '\e934';
}
.lc-arrow-next-16-regular:before {
  content: '\e935';
}
.lc-next-16-regular:before {
  content: '\e936';
}
.lc-chevron-right-12-regular:before {
  content: '\e937';
}
.lc-search-info-24-regular:before {
  content: '\e938';
}
.lc-search-16-regular:before {
  content: '\e939';
}
.lc-eye-16-regular:before {
  content: '\e93a';
}
.lc-data-usage-edit-20-regular:before {
  content: '\e93b';
}
.lc-cloud-edit-16-regular:before {
  content: '\e93c';
}
.lc-edit-prohibited-16-regular:before {
  content: '\e93d';
}
.lc-edit-16-regular:before {
  content: '\e93e';
}
.lc-person-delete-24-regular:before {
  content: '\e93f';
}
.lc-delete-dismiss-20-regular:before {
  content: '\e940';
}
.lc-delete-16-regular:before {
  content: '\e941';
}
.lc-folder-add-16-regular:before {
  content: '\e942';
}
.lc-add-square-multiple-16-regular:before {
  content: '\e943';
}
.lc-add-square-16-regular:before {
  content: '\e944';
}
.lc-add-16-regular:before {
  content: '\e945';
}
