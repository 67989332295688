.result_line {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  .result_text {
    margin: 20px 0 10px 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 40px;
  }

  .result_ident {
    text-decoration: underline;
  }

  svg {
    width: 72px;
    height: 72px;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbfafa;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 48px;
  user-select: unset;
}

.element {
  width: 160px;
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left_element {
  width: 160px;
  height: 40px;
  text-align: right;
}
