.menu {
  // width: 100%;
  align-items: center;

  .menu_item {
    display: flex;
    font-size: 16px !important;
    font-weight: 550;
  }

  .label_tag {
    transform: translate(4px, -4px);

    img {
      width: 26px;
      height: 18px;
    }
  }

  gap: 44px;

  :global {
    .ant-menu-overflow-item {
      order: inherit !important;
      .ant-menu-title-content {
        font-size: 16px !important;
        font-weight: 550;
      }
    }
  }

  :global(.ant-menu-item) {
    color: var(--custom-menu-top-color);
    padding: 0 !important;
    height: 36px;
    line-height: 36px;
    margin-top: -4px !important;

    &::after {
      width: 100%;
      height: 2px;
      border: none !important;
      left: 0;
    }
  }

  :global(.ant-menu-item-selected) {
    color: var(--custom-menu-top-active-color) !important;

    &::after {
      background-color: var(--custom-menu-top-active-color);
    }
  }

  :global(.ant-menu-sub) {
    background: transparent;
  }

  :global(.ant-menu-submenu) {
    color: var(--custom-menu-top-color) !important;
    margin-top: -4px !important;
    padding: 0 !important;

    &:hover::after {
      background: var(--custom-menu-top-sub-active-icon) no-repeat;
      background-size: 100% 100%;
    }

    &::after {
      background: var(--custom-menu-top-sub-icon) no-repeat;
      background-size: 100% 100%;
      width: 16px;
      height: 16px;
      left: auto;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      border: none !important;
      display: none;
    }
  }

  :global(.ant-menu-submenu-selected) {
    :global(.ant-menu-submenu-title) {
      &:nth-child(1) {
        color: var(--custom-menu-top-active-color);
      }
    }

    &::after {
      background: var(--custom-menu-top-sub-active-icon) no-repeat;
      background-size: 100% 100%;
    }
  }

  :global(.ant-menu-submenu-title) {
    color: var(--custom-menu-top-color);
  }
}
