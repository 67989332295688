#root {
  height: 100%;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "\5fae\8f6f\96c5\9ed1", Arial, sans-serif,
    "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "\5fae\8f6f\96c5\9ed1", Arial, sans-serif;
}

a,
a:hover {
  color: var(--custom-link-color);
}

.btn {
  margin-right: 8px;
  margin-bottom: 12px;
}

.search-form {
  & .custom-col {
    margin-bottom: 0 !important;
  }

  & .custom-form-item,
  & .ant-form-item {
    margin-bottom: 8px !important;
  }
}

.fields-searches {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.btn-group {
  margin-bottom: 8px;
}

.actions {
  display: flex;
  flex-wrap: nowrap;

  button:last-child {
    margin-right: 0;
  }
}

.dc-input-number,
.dc-input-date {
  width: 100% !important;
}

.text-right {
  text-align: right;
}

.m-r-8 {
  margin-right: 8px;
  vertical-align: top;
}

.dc-upload {
  .ant-upload-list-item {
    margin-top: 5px;
  }
}

// 按钮样式
.ant-btn {
  border-radius: 4px;

  &:active {
    color: #fe7134 !important;
  }
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active {
  color: #fe7134 !important;
}

.ant-btn-primary {
  background-color: #7f8dff;
  border-color: #7f8dff;

  &:active {
    background-color: #596bfe;
    border-color: #596bfe;
    color: #fe7134 !important;
  }
}

// 表格
.ant-table {
  .ant-table-summary {
    .ant-table-cell {
      padding-left: 40px;
    }
  }
  // border-top: 1px solid #dbdfea;
  // border-bottom: 1px solid #dbdfea;
  // border-right: 1px solid #dbdfea;
  // border-left: 1px solid #dbdfea;
  border: none !important;

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    height: 40px;
    padding: 0 16px;

    &:not(:first-child) {
      border-left: 1px solid #dbdfea;
    }
  }

  .ant-table-thead > tr > th {
    background-color: #e2e4f4;
    font-weight: 700;
    color: #364a63;
  }

  .ant-table-tbody > tr > td {
    color: #8692a1;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: #f2f3ff;
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: #e2e4f4;
  }
}

.ant-table.ant-table-bordered.ant-table-small
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-small
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed {
  margin: -16px -17px;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed {
  margin: -16px -20px;
}

// 翻页器
.ant-pagination {
  .ant-pagination-item,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-radius: 4px;
  }

  .ant-pagination-item a,
  .ant-select {
    color: #8692a1;
  }

  .ant-pagination-item-active {
    background: #6576ff;
  }
}

// // 标签页 type="card" 的选中样式
// .ant-tabs-tab-active {
//   background-color: red;
//   position: relative;
//   .ant-tabs-tab-btn {
//     color: #6576ff !important;
//   }
//   &::after {
//     display: inline-block;
//     height: 4px;
//     width: 100%;
//     background-color: #6576ff;
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     box-shadow: 0px 2px 8px #6576ff;
//     border-top-left-radius: 2px;
//     border-top-right-radius: 2px;
//   }
// }

// // 标签页 type="card" 的常规样式
// .ant-tabs-tab {
//   background: initial;
//   margin-left: 0px !important;
// }

// 多行隐藏
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.ellipsisClamp1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.ellipsisClamp3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

// 导航大图
.banner {
  width: 100%;
  height: 470px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.89) 0%,
    rgba(255, 255, 255, 0.59) 100%
  );
  position: relative;

  .bannerImg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .desc {
    position: absolute;
    left: calc(50% - 636px);
    top: 142px;

    h5 {
      font-size: 40px;
      font-family: RTWS BanHei G0v1, RTWS BanHei G0v1;
      font-weight: normal;
      color: #353535;
      line-height: 50px;
      // letter-spacing: 30px;
      margin-bottom: 30px;
    }

    .btn {
      width: max-content;
    }

    & > span {
      font-size: 20px;
      line-height: 30px;
      font-family: "Helvetica Neue", Helvetica, "PingFang SC",
        "Hiragino Sans GB", "Microsoft YaHei", "\5fae\8f6f\96c5\9ed1", Arial,
        sans-serif, "Helvetica Neue", Helvetica, "PingFang SC",
        "Hiragino Sans GB", "Microsoft YaHei", "\5fae\8f6f\96c5\9ed1", Arial,
        sans-serif;
      font-weight: 400;
      color: #7a8ba6;
      display: inline-block;
      width: 789px;
    }
  }
}

// banner图上面的tab切换 目前兼容三个 （关于我们 / 人才培训）
.bannerTabs {
  width: 1290px;
  height: 54px;
  position: absolute;
  top: 416px;
  left: calc(50% - 645px);
  display: flex;

  > div {
    cursor: pointer;
    height: 100%;
    width: 33.33%;
    background: linear-gradient(270deg, #ff9849 0%, #fe7034 100%);
    border-radius: 0px 0px 0px 0px;
    opacity: 0.6;
    font-size: 22px;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
      "Microsoft YaHei", "\5fae\8f6f\96c5\9ed1", Arial, sans-serif,
      "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
      "Microsoft YaHei", "\5fae\8f6f\96c5\9ed1", Arial, sans-serif;
    font-weight: 600;
    text-align: center;
    line-height: 54px;
    color: #ffffff;

    &:first-child {
      border-radius: 60px 0px 0px 0px;
      border-right: 1px solid #fff;
    }

    &:last-child {
      border-radius: 0px 60px 0px 0px;
      border-left: 1px solid #fff;
    }

    &.active {
      opacity: 1;
    }
  }
}
