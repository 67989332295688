.footer {
  // height: 437px;
  color: #fff;
  background: linear-gradient(180deg, #66340e 0%, #3b1400 100%);
}

.logo {
  padding: 20px 0 34px;
}

.title {
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  font-family: FZZhengHeiS-DB-GB, FZZhengHeiS-DB-GB;
}

.content {
  width: 1700px;
  padding: 33px 0 13px;
  margin: auto;
  display: flex;
  justify-content: space-between;

  .left {
    flex: 1;
    padding-right: 84px;
    border-right: 1px solid rgba(255, 255, 255, 0.11);

    .logo {
      display: flex;
      align-items: center;
      gap: 17px;

      > img {
        width: auto;
        height: 52px;
      }
    }
  }

  .menuWrapper {
    display: flex;
    justify-content: space-between;
    gap: 44px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    .menuName {
      font-size: 20px;
      padding-bottom: 30px;
      white-space: nowrap;
    }

    .submenuWrap {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .submenuName {
      color: #999;
      font-size: 18px;
    }

    .submenuName {
      cursor: pointer;

      &:hover {
        color: #fe7336;
      }
    }
  }

  .menuWrapperTitle {
    .menuWrapper();
    margin-bottom: 30px;
    font-size: 20px;
  }
  .menuWrapperList{
    .menuWrapper();
    flex-wrap: wrap;
    gap: 20px 80px;
  }

  .right {
    width: 400px;
    padding-left: 84px;
    padding-top: 12px;
  }

  .blockTitle {
    margin-bottom: 18px;
    font-size: 20px;
  }

  .followUs {
    display: flex;
    gap: 20px;

    .followUs_item {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 76px;
        height: 76px
      }

      .followUs_name {
        margin: 10px 0;
        font-size: 12px;
        color: #999;
      }
    }
  }

  .linkP {
    padding: 5px 0 18px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .name {
      color: #999;
    }
  }

  .subjectName {
    width: 240px;
  }

  .address {
    width: 240px;
    color: #999;
    cursor: pointer;

    &:hover {
      color: #fe7235;
    }
  }

  .subjectName, .address {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .link {
    cursor: pointer;
  }

  :global {
    .ant-descriptions-item-label {
      white-space: nowrap;
    }

    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      color: #999;
      font-weight: 400;
      font-size: 18px;
    }

    .ant-descriptions-row > td {
      padding-bottom: 20px;
    }
  }
}

.bottom {
  height: 76px;
  line-height: 76px;
  text-align: center;
  background: rgba(0, 0, 0, 0.17);
  font-weight: 400;
  color: #999999;
  font-size: 16px;

  a {
    color: #999999;

    img {
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-left: 5px;
      margin-right: 2px;
    }
  }
}

.modalWrapper {
  :global {
    .ant-modal-confirm-btns {
      text-align: center;
    }

    .ant-modal-confirm-content {
      p {
        text-indent: 2em;
      }
    }
  }
}
